import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Head from "../components/head"
const IndexPage = () => (
  <Layout>
    <Head
      title="メンバー紹介"
      description="株式会社アイテクスで働くメンバーをご紹介致します。"
    />
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">メンバー紹介</h1>
          <h2 className="subtitle">Member</h2>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <div className="columns is-variable is-5 is-multiline">
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-nagayama.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">永山 邦哉</p>
            <p className="has-text-grey is-size-7">代表取締役 社長</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-yamashita.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">山下 里美</p>
            <p className="has-text-grey is-size-7">マネージャー／エンジニア</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-ogawa.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">小川 一彦</p>
            <p className="has-text-grey is-size-7">シニアエンジニア</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-mitsuoka.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">光岡 拓郎</p>
            <p className="has-text-grey is-size-7">エンジニア</p>
          </div>
          {/* <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-kubo.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">久保 和史</p>
            <p className="has-text-grey is-size-7">エンジニア</p>
          </div> */}
          {/* <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-kamimura.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">上村 光</p>
            <p className="has-text-grey is-size-7">エンジニア</p>
          </div> */}
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-kubuki.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">久富木 慎太郎</p>
            <p className="has-text-grey is-size-7">ウェブクリエーター</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <StaticImage
              src="../../content/assets/4x3-hyamashita.jpg"
              className="image-rounded"
              placeholder="blurred"
              layout="fullWidth"
            />
            <p className="mt-3">山下 裕恵</p>
            <p className="has-text-grey is-size-7">ビジネスサポート</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default IndexPage
